// Here you can add other styles
.pos-common-texfield-select,
.common-texfield-datetime {
  width: 100%;
  height: 100%;
}

.pos-common-texfield-select.header-select,
.common-texfield-datetime.header-select {
  .MuiInput-underline::before {
    border-bottom-width: 2px;
    border-bottom-color: $main-color;
  }

  .MuiInput-underline::after {
    border-bottom-width: 2px;
    border-bottom-color: $main-color;
  }

  .MuiInputLabel-formControl {
    font-weight: bold;
  }

  path {
    color: $main-color;
  }
}

.common-texfield-datetime {
  .MuiInputBase-input {
    height: 1.4rem;
  }
}

.MuiOutlinedInput {
  &-root {
    border-radius: 2px !important;
  }
}

.MuiAutocomplete {
  &-inputRoot {
    padding: 0 !important;
  }
}

.modal-dialog {
  .modal-content {
    .modal-header {
      padding: 12px 15px;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      background: #fcfcfc;
      color: #585d63;
      position: relative;

      .modal-title {
        font-size: 1.1rem;
      }
      & strong {
        padding-left: 5px;
      }

      .btn-close {
        position: absolute;
        right: 10px;
        background: #fff;
        padding: 0.25rem;
        line-height: 1;
        border-radius: 3px;
      }
    }

    .modal-body {
      .list-group-item {
        padding: 15px 5px;
      }
      .tab-content {
        border: 0;

        .tab-pane {
          padding: 0;
        }
      }

      .pos-customer-point {
        .MuiFormControlLabel-root {
          margin-right: 5px;
        }
        .MuiFormControlLabel-label {
          font-size: 0.93rem;
        }
      }
      .error_line_bottom {
        border-bottom: 1px solid red;
      }
    }

    .modal-footer {
      padding-top: 8px;
      padding-bottom: 8px;
      background: #e1d6be;
      justify-content: space-between;
      &.button-end-right {
        justify-content: flex-end;
      }
    }
  }
}

.form-group {
  & div[class$='-control'] {
    border-radius: 2px;
  }
}

.react-bs-table-container {
  .react-bs-table {
    .react-bs-container-header {
      & > table {
        tr {
          & th {
            background-color: #495165;
            font-weight: 500;
            color: whitesmoke;
            padding-top: 0.35rem;
            padding-bottom: 0.45rem;
            border-bottom-width: 1px;
            z-index: 1;
          }
          & th:nth-child(1) {
            text-overflow: unset;
            cursor: pointer;
            input {
              cursor: pointer;
            }
          }
          & th:nth-child(2) {
            border-left: none;
          }
          & th:last-child {
            border-right: none;
          }
        }
      }
    }

    .react-bs-container-body {
      & > table {
        tr {
          & td {
            padding: 0.25rem;
            vertical-align: middle;
            z-index: 99;
          }
          & td:nth-child(2) {
            border-left: none;
          }
          & td:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .react-bs-table-tool-bar {
    & select {
      padding-top: 2px !important;
    }
  }

  .react-bs-table-bordered {
    border-color: #73818f;
    border-radius: 2px;
  }

  .react-bs-table-pagination {
    & > div {
      margin: 0px;
      & button#pageDropDown {
        margin-left: 0.5rem;
      }
    }
  }
}

.pos-result-body {
  padding: 0.5rem;
  .react-bootstrap-table-page-btns-ul {
    margin-bottom: 0px;
  }
}

.responsive-table {
  overflow-x: auto;
  .responsive-table-header {
    min-width: 1158px;
  }
  .responsive-table-body {
    min-width: 1158px;
  }
}

.iphone {
  width: 300px;
  height: 609px;
  background-image: url('./../assets/img/frame-phone.jpg');
  background-size: 100% 100%;
  margin: 0 auto;
  position: relative;

  .head-preview {
    position: absolute;
    top: 45px;
    left: 36%;
  }

  .border {
    position: absolute;
    top: 12.3%;
    right: 7%;
    left: 7%;
    bottom: 12%;
    overflow: hidden;
  }

  .messages {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .yours {
    align-items: flex-start;
  }

  .yours .message {
    margin-right: 3%;
    background-color: #eee;
    position: relative;
    max-height: 310px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .yours .message.last:before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
  }

  .yours .message.last:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
  }

  .image-area {
    margin-top: 10px;
    .thumb-image-area {
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: 1281px) {
  .react-bs-container-body {
    & > table {
      & td {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 845px) {
  .react-bs-table-container {
    .react-bs-table {
      .react-bs-container-header {
        & > table {
          tr {
            & th {
              &.mb_sticky {
                position: sticky;
                top: 0;
                z-index: 2;
                background: #495165;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
                left: 0px;
              }
              &.sticky_right {
                position: sticky;
                top: 0;
                z-index: 1;
                background: #495165;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
                right: 0px;
              }
            }
          }
        }
      }

      .react-bs-container-body {
        & > table {
          tr {
            & td {
              &.mb_sticky {
                position: sticky;
                top: 0;
                z-index: 1;
                background: #f5f5f5;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
                left: 0px;
              }
              &.sticky_right {
                position: sticky;
                top: 0;
                z-index: 1;
                background: #f5f5f5;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
                right: 0px;
              }
            }
          }
        }
      }

      th.mb_sticky::after,
      td.mb_sticky::after {
        content: '';
        position: absolute;
        right: -6px;
        top: 0;
        bottom: -1px;
        width: 5px;
        border-left: 1px solid #c9d1dc;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%);
        border: 1px solid #c9d1dc;
        box-sizing: initial;
      }

      th.mb_sticky::before,
      td.mb_sticky::before {
        content: '';
        position: absolute;
        left: -6px;
        top: 0;
        bottom: -1px;
        width: 5px;
        border-right: 1px solid #c9d1dc;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
        box-sizing: initial;
      }
    }
  }

  table.reponsive-table-details {
    &.table-bordered {
      border: none;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
      background-color: #f8f8f8;
    }

    td {
      border-bottom: 1px solid #ddd;
      border-top: none;
      border-left: none;
      border-right: none;
      display: block;
      font-size: 0.8em;
      text-align: right !important;
      padding: 0.35rem 0.5rem !important;
      &.refund-total,
      &.payment-total,
      &.payments,
      &.return-amount {
        width: 100%;
      }
    }

    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}
