$main-color: #425c70;
$main-color-hover: #009688;
$sub-main-color: #f6d688;
$org-color: #d15124;
$org-color-hover: #ae431e;
$red-main-color: #f16482;
$gray-light-color: #828282;
$gray-color: #4f4f4f;
$green-color: #458c95;
$green-color-hover: #2a5f66;
$purple-color: #bb6bd9;
$blue-color: #2196f3;
$blue-color-hover: #0563ae;
$red-color: #ff0000;
$red-light-color: #eb5757;
$dark-color: #2c2c2c;
$dark-white-color: #f5f3f3;
$white-color: #ffffff;
$main-menu-color: #458c95;
$main-menu-hover-color: #49a3af;

$main-success-color: #0ea141;
$main-success-hover-color: #19d49c;
