#overlay,
#overlay-module {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .terminal-processing {
    background: #e0f3ff;
    border-radius: 3px;
    padding: 1rem;
    .time-remain {
      font-size: 1.5rem;
      text-align: center;
      font-weight: bold;
    }
  }
}
