$main-icon-color: #ffffff;
$main-icons-7xl: 3rem;
$main-icons-6xl: 2.5rem;
$main-icons-4xl: 2.15rem;
$main-icons-2xl: 1.5rem;
$main-icons-1lg: 1.2rem;
$main-icons-3xl: 1.75rem;
$main-icons-1xl: 13px;

.font-1xs {
  height: $main-icons-1xl !important;
  -webkit-mask-size: $main-icons-1xl !important;
  mask-size: $main-icons-1xl !important;
}

.font-1xs-checkout {
  height: 10px !important;
  -webkit-mask-size: 6px !important;
  mask-size: 6px !important;
  width: 14px;
}

.font-6xl {
  height: $main-icons-6xl !important;
  -webkit-mask-size: $main-icons-6xl !important;
  mask-size: $main-icons-6xl !important;
  width: auto;
}

.font-4xl {
  height: $main-icons-4xl !important;
  -webkit-mask-size: $main-icons-4xl !important;
  mask-size: $main-icons-4xl !important;
}

.w-4xl {
  width: $main-icons-4xl !important;
}

.font-3xl {
  height: $main-icons-3xl !important;
  -webkit-mask-size: $main-icons-3xl !important;
  mask-size: $main-icons-3xl !important;
}

.w-3xl {
  width: $main-icons-3xl !important;
}

.font-big-xl {
  height: $main-icons-4xl !important;
  -webkit-mask-size: $main-icons-3xl !important;
  mask-size: $main-icons-3xl !important;
}

.w-big-xl {
  width: $main-icons-4xl !important;
}

.font-2xl {
  height: $main-icons-2xl !important;
  -webkit-mask-size: $main-icons-2xl !important;
  mask-size: $main-icons-2xl !important;
}

.w-2xl {
  width: $main-icons-2xl !important;
}

.font-1lg {
  height: $main-icons-1lg !important;
  -webkit-mask-size: $main-icons-1lg !important;
  mask-size: $main-icons-1lg !important;
}

.font-7xl {
  height: $main-icons-7xl !important;
  -webkit-mask-size: $main-icons-7xl !important;
  mask-size: $main-icons-7xl !important;
  width: $main-icons-7xl !important;
}

i.spinner-border {
  width: 1rem;
  height: 1rem;
}

.p-icon-1 {
  padding: 0.35rem 0.25rem !important;
}

.p-icon-2 {
  padding: 0.75rem !important;
}

.w-icon-40 {
  width: 35px;
  height: 25px;
}

.w-icon-30 {
  width: 30px;
  height: 20px;
}

.icon-cover-30 {
  width: 30px;
  height: 30px;
}

.w-icon-20 {
  width: 20px;
  height: 18px;
}

.w-icon-15 {
  width: 15px;
  height: 15px;
}

.c-icon-red {
  background-color: #f86c6b !important;
}

.c-icon-green {
  background-color: green !important;
}

.c-icon-black {
  background-color: #585d63 !important;
}

.c-icon-white {
  background-color: #ffffff !important;
}

.c-icon-teal {
  background-color: #425c70 !important;
}

.w-p-100 {
  width: 100%;
}

.modal-title,
.card-header {
  i {
    background: #585d63;
  }
}

.react-bs-table-tool-bar {
  .icon-question {
    background: #585d63;
  }
}

.pos-common-modal-custom {
  .modal-header {
    i {
      background: #585d63 !important;
    }
  }
}

button {
  align-items: center;
  justify-content: center;
}

.modal-footer {
  button {
    display: flex;
    .cil-x-circle {
      background-color: #f50909e6 !important;
    }
  }
}

.list-item-detail {
  .btn-receipt-group {
    display: flex;
    button {
      display: flex;
      i {
        height: 20px;
        min-width: 20px;
      }
      .cil-pencil {
        background-color: #73818f !important;
      }
      .cil-trash {
        background-color: #f50909e6 !important;
      }
      .cil-plus {
        background-color: green !important;
      }
      .cil-minus {
        background-color: #f86c6b !important;
      }
    }
  }
}

i {
  display: inline-flex;
  height: 16px;
  background-color: $main-icon-color;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  min-width: 18px;
  align-items: center;
  justify-content: center;

  &.white-color {
    background: #ffffff !important;
  }
  &.cil-house,
  &.cil-home {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-home.svg');
    mask-image: url('../assets/img/icons/svg/cil-home.svg');
  }
  &.cil-check-in {
    -webkit-mask-image: url('../assets/img/icons/svg/check-in.svg');
    mask-image: url('../assets/img/icons/svg/check-in.svg');
  }
  &.cil-employee {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-employee.svg');
    mask-image: url('../assets/img/icons/svg/cil-employee.svg');
  }
  &.cil-pos {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-pos.svg');
    mask-image: url('../assets/img/icons/svg/cil-pos.svg');
  }
  &.cil-gem {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-gem.svg');
    mask-image: url('../assets/img/icons/svg/cil-gem.svg');
  }
  &.cil-3d {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-3d.svg');
    mask-image: url('../assets/img/icons/svg/cil-3d.svg');
  }

  &.cil-account-logout {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-account-logout.svg');
    mask-image: url('../assets/img/icons/svg/cil-account-logout.svg');
  }

  &.cil-address-book {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-address-book.svg');
    mask-image: url('../assets/img/icons/svg/cil-address-book.svg');
  }

  &.cil-alarm {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-alarm.svg');
    mask-image: url('../assets/img/icons/svg/cil-alarm.svg');
  }

  &.cil-aperture {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-aperture.svg');
    mask-image: url('../assets/img/icons/svg/cil-aperture.svg');
  }

  &.cil-arrow-right {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-arrow-right.svg');
    mask-image: url('../assets/img/icons/svg/cil-arrow-right.svg');
  }

  &.cil-at {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-at.svg');
    mask-image: url('../assets/img/icons/svg/cil-at.svg');
  }

  &.cil-backspace {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-backspace.svg');
    mask-image: url('../assets/img/icons/svg/cil-backspace.svg');
  }

  &.cil-ban {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-ban.svg');
    mask-image: url('../assets/img/icons/svg/cil-ban.svg');
  }

  &.cil-birthday-cake {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-birthday-cake.svg');
    mask-image: url('../assets/img/icons/svg/cil-birthday-cake.svg');
  }

  &.cil-bold {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-bold.svg');
    mask-image: url('../assets/img/icons/svg/cil-bold.svg');
  }

  &.cil-bookmark {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-bookmark.svg');
    mask-image: url('../assets/img/icons/svg/cil-bookmark.svg');
  }

  &.cil-braille {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-braille.svg');
    mask-image: url('../assets/img/icons/svg/cil-braille.svg');
  }

  &.cil-briefcase-time {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-briefcase-time.svg');
    mask-image: url('../assets/img/icons/svg/cil-briefcase-time.svg');
  }

  &.cil-briefcase {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-briefcase.svg');
    mask-image: url('../assets/img/icons/svg/cil-briefcase.svg');
  }

  &.cil-browser {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-browser.svg');
    mask-image: url('../assets/img/icons/svg/cil-browser.svg');
  }

  &.cil-building {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-building.svg');
    mask-image: url('../assets/img/icons/svg/cil-building.svg');
  }

  &.cil-calculator {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-calculator.svg');
    mask-image: url('../assets/img/icons/svg/cil-calculator.svg');
  }

  &.cil-calendar {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-calendar.svg');
    mask-image: url('../assets/img/icons/svg/cil-calendar.svg');
  }

  &.cil-camera-control {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-camera-control.svg');
    mask-image: url('../assets/img/icons/svg/cil-camera-control.svg');
  }

  &.cil-cash-i {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-cash-i.svg');
    mask-image: url('../assets/img/icons/svg/cil-cash-i.svg');
  }

  &.cil-cash {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-cash.svg');
    mask-image: url('../assets/img/icons/svg/cil-cash.svg');
  }

  &.cil-chart-pie {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-chart-pie.svg');
    mask-image: url('../assets/img/icons/svg/cil-chart-pie.svg');
  }

  &.cil-check-circle {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-check-circle.svg');
    mask-image: url('../assets/img/icons/svg/cil-check-circle.svg');
  }

  &.cil-check {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-check.svg');
    mask-image: url('../assets/img/icons/svg/cil-check.svg');
  }

  &.cil-chevron-double-left {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-chevron-double-left.svg');
    mask-image: url('../assets/img/icons/svg/cil-chevron-double-left.svg');
  }

  &.cil-chevron-double-right {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-chevron-double-right.svg');
    mask-image: url('../assets/img/icons/svg/cil-chevron-double-right.svg');
  }

  &.cil-clock {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-clock.svg');
    mask-image: url('../assets/img/icons/svg/cil-clock.svg');
  }

  &.cil-color-palette {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-color-palette.svg');
    mask-image: url('../assets/img/icons/svg/cil-color-palette.svg');
  }

  &.cil-comment-bubble {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-comment-bubble.svg');
    mask-image: url('../assets/img/icons/svg/cil-comment-bubble.svg');
  }

  &.cil-contact {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-contact.svg');
    mask-image: url('../assets/img/icons/svg/cil-contact.svg');
  }

  &.cil-credit-card-i {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-credit-card-i.svg');
    mask-image: url('../assets/img/icons/svg/cil-credit-card-i.svg');
  }

  &.cil-credit-card {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-credit-card.svg');
    mask-image: url('../assets/img/icons/svg/cil-credit-card.svg');
  }

  &.cil-diamond {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-diamond.svg');
    mask-image: url('../assets/img/icons/svg/cil-diamond.svg');
  }

  &.cil-discount {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-discount.svg');
    mask-image: url('../assets/img/icons/svg/cil-discount.svg');
  }

  &.cil-dollar {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-dollar.svg');
    mask-image: url('../assets/img/icons/svg/cil-dollar.svg');
  }

  &.cil-envelope-letter {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-envelope-letter.svg');
    mask-image: url('../assets/img/icons/svg/cil-envelope-letter.svg');
  }

  &.cil-filter {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-filter.svg');
    mask-image: url('../assets/img/icons/svg/cil-filter.svg');
  }

  &.cil-flag-alt {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-flag-alt.svg');
    mask-image: url('../assets/img/icons/svg/cil-flag-alt.svg');
  }

  &.cil-gift {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-gift.svg');
    mask-image: url('../assets/img/icons/svg/cil-gift.svg');
  }

  &.cil-grid {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-grid.svg');
    mask-image: url('../assets/img/icons/svg/cil-grid.svg');
  }

  &.cil-heart {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-heart.svg');
    mask-image: url('../assets/img/icons/svg/cil-heart.svg');
  }

  &.cil-https {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-https.svg');
    mask-image: url('../assets/img/icons/svg/cil-https.svg');
  }

  &.cil-income {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-income.svg');
    mask-image: url('../assets/img/icons/svg/cil-income.svg');
  }

  &.cil-info {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-info.svg');
    mask-image: url('../assets/img/icons/svg/cil-info.svg');
  }

  &.cil-laptop {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-laptop.svg');
    mask-image: url('../assets/img/icons/svg/cil-laptop.svg');
  }

  &.cil-layers {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-layers.svg');
    mask-image: url('../assets/img/icons/svg/cil-layers.svg');
  }

  &.cil-library-add {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-library-add.svg');
    mask-image: url('../assets/img/icons/svg/cil-library-add.svg');
  }

  &.cil-list-rich {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-list-rich.svg');
    mask-image: url('../assets/img/icons/svg/cil-list-rich.svg');
  }

  &.cil-lock {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-lock.svg');
    mask-image: url('../assets/img/icons/svg/cil-lock.svg');
  }

  &.cil-loop-circular {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-loop-circular.svg');
    mask-image: url('../assets/img/icons/svg/cil-loop-circular.svg');
  }

  &.cil-low-vision {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-low-vision.svg');
    mask-image: url('../assets/img/icons/svg/cil-low-vision.svg');
  }

  &.cil-management {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-management.svg');
    mask-image: url('../assets/img/icons/svg/cil-management.svg');
  }

  &.cil-menu {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-menu.svg');
    mask-image: url('../assets/img/icons/svg/cil-menu.svg');
  }

  &.cil-merge {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-merge.svg');
    mask-image: url('../assets/img/icons/svg/cil-merge.svg');
  }

  &.cil-money {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-money.svg');
    mask-image: url('../assets/img/icons/svg/cil-money.svg');
  }

  &.cil-opacity {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-opacity.svg');
    mask-image: url('../assets/img/icons/svg/cil-opacity.svg');
  }

  &.cil-paper-plane {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-paper-plane.svg');
    mask-image: url('../assets/img/icons/svg/cil-paper-plane.svg');
  }

  &.cil-pencil {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-pencil.svg');
    mask-image: url('../assets/img/icons/svg/cil-pencil.svg');
  }

  &.cil-people {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-people.svg');
    mask-image: url('../assets/img/icons/svg/cil-people.svg');
  }

  &.cil-plus {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-plus.svg');
    mask-image: url('../assets/img/icons/svg/cil-plus.svg');
  }

  &.cil-print {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-print.svg');
    mask-image: url('../assets/img/icons/svg/cil-print.svg');
  }

  &.cil-revenue {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-revenue.svg');
    mask-image: url('../assets/img/icons/svg/cil-revenue.svg');
  }

  &.cil-sale {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-sale.svg');
    mask-image: url('../assets/img/icons/svg/cil-sale.svg');
  }

  &.cil-save {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-save.svg');
    mask-image: url('../assets/img/icons/svg/cil-save.svg');
  }

  &.cil-search {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-search.svg');
    mask-image: url('../assets/img/icons/svg/cil-search.svg');
  }

  &.cil-settings {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-settings.svg');
    mask-image: url('../assets/img/icons/svg/cil-settings.svg');
  }

  &.cil-share-alt {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-share-alt.svg');
    mask-image: url('../assets/img/icons/svg/cil-share-alt.svg');
  }

  &.cil-sms,
  &.cil-chat-bubble {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-sms.svg');
    mask-image: url('../assets/img/icons/svg/cil-sms.svg');
  }

  &.cil-spa {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-spa.svg');
    mask-image: url('../assets/img/icons/svg/cil-spa.svg');
  }

  &.cil-speech {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-speech.svg');
    mask-image: url('../assets/img/icons/svg/cil-speech.svg');
  }

  &.cil-tachometer {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-tachometer.svg');
    mask-image: url('../assets/img/icons/svg/cil-tachometer.svg');
  }

  &.cil-ticket-full {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-ticket-full.svg');
    mask-image: url('../assets/img/icons/svg/cil-ticket-full.svg');
  }

  &.cil-ticket {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-ticket.svg');
    mask-image: url('../assets/img/icons/svg/cil-ticket.svg');
  }

  &.cil-trash {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-trash.svg');
    mask-image: url('../assets/img/icons/svg/cil-trash.svg');
  }

  &.cil-user-female {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-user-female.svg');
    mask-image: url('../assets/img/icons/svg/cil-user-female.svg');
  }

  &.cil-user-follow {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-user-follow.svg');
    mask-image: url('../assets/img/icons/svg/cil-user-follow.svg');
  }

  &.cil-user {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-user.svg');
    mask-image: url('../assets/img/icons/svg/cil-user.svg');
  }

  &.cil-view-module {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-view-module.svg');
    mask-image: url('../assets/img/icons/svg/cil-view-module.svg');
  }

  &.cil-x-circle {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-x-circle.svg');
    mask-image: url('../assets/img/icons/svg/cil-x-circle.svg');
  }

  &.fingerprint-solid {
    -webkit-mask-image: url('../assets/img/icons/svg/fingerprint-solid.svg');
    mask-image: url('../assets/img/icons/svg/fingerprint-solid.svg');
  }

  &.franchise {
    -webkit-mask-image: url('../assets/img/icons/svg/franchise.svg');
    mask-image: url('../assets/img/icons/svg/franchise.svg');
  }

  &.go-to-store {
    -webkit-mask-image: url('../assets/img/icons/svg/go-to-store.svg');
    mask-image: url('../assets/img/icons/svg/go-to-store.svg');
  }

  &.icon-check {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-check.svg');
    mask-image: url('../assets/img/icons/svg/icon-check.svg');
  }

  &.icon-cloud-download {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-cloud-download.svg');
    mask-image: url('../assets/img/icons/svg/icon-cloud-download.svg');
  }

  &.icon-cloud-upload,
  &.cil-cloud-upload {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-cloud-upload.svg');
    mask-image: url('../assets/img/icons/svg/icon-cloud-upload.svg');
  }

  &.icon-magnifier {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-magnifier.svg');
    mask-image: url('../assets/img/icons/svg/icon-magnifier.svg');
  }

  &.icon-note {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-note.svg');
    mask-image: url('../assets/img/icons/svg/icon-note.svg');
  }

  &.icon-question {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-question.svg');
    mask-image: url('../assets/img/icons/svg/icon-question.svg');
  }

  &.icon-related {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-related.svg');
    mask-image: url('../assets/img/icons/svg/icon-related.svg');
  }

  &.people-arrows-solid {
    -webkit-mask-image: url('../assets/img/icons/svg/people-arrows-solid.svg');
    mask-image: url('../assets/img/icons/svg/people-arrows-solid.svg');
  }

  &.pos-terminal {
    -webkit-mask-image: url('../assets/img/icons/svg/pos-terminal.svg');
    mask-image: url('../assets/img/icons/svg/pos-terminal.svg');
  }

  &.user-check-solid {
    -webkit-mask-image: url('../assets/img/icons/svg/user-check-solid.svg');
    mask-image: url('../assets/img/icons/svg/user-check-solid.svg');
  }

  &.user-lock-solid,
  &.icon-lock {
    -webkit-mask-image: url('../assets/img/icons/svg/user-lock-solid.svg');
    mask-image: url('../assets/img/icons/svg/user-lock-solid.svg');
  }
  &.cil-back-end {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-back-end.svg');
    mask-image: url('../assets/img/icons/svg/cil-back-end.svg');
  }
  &.fa-image {
    -webkit-mask-image: url('../assets/img/icons/svg/fa-image.svg');
    mask-image: url('../assets/img/icons/svg/fa-image.svg');
  }
  &.icon-arrow-down {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-arrow-down.svg');
    mask-image: url('../assets/img/icons/svg/icon-arrow-down.svg');
  }
  &.icon-arrow-up {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-arrow-up.svg');
    mask-image: url('../assets/img/icons/svg/icon-arrow-up.svg');
  }
  &.cil-minus {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-minus.svg');
    mask-image: url('../assets/img/icons/svg/cil-minus.svg');
  }
  &.cil-calendar-check {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-calendar-check.svg');
    mask-image: url('../assets/img/icons/svg/cil-calendar-check.svg');
  }
  &.cil-history {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-history.svg');
    mask-image: url('../assets/img/icons/svg/cil-history.svg');
  }
  &.icon-phone {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-phone.svg');
    mask-image: url('../assets/img/icons/svg/icon-phone.svg');
  }
  &.icon-phone-flip {
    -webkit-mask-image: url('../assets/img/icons/svg/icon_circle_phone_flip.svg');
    mask-image: url('../assets/img/icons/svg/icon_circle_phone_flip.svg');
  }
  &.cil-spreadsheet {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-spreadsheet.svg');
    mask-image: url('../assets/img/icons/svg/cil-spreadsheet.svg');
  }
  &.cib-pandora {
    -webkit-mask-image: url('../assets/img/icons/svg/cib-pandora.svg');
    mask-image: url('../assets/img/icons/svg/cib-pandora.svg');
  }
  &.cil-chevron-circle-down-alt {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-chevron-circle-down-alt.svg');
    mask-image: url('../assets/img/icons/svg/cil-chevron-circle-down-alt.svg');
  }
  &.cil-more {
    -webkit-mask-image: url('../assets/img/icons/svg/icon-more.svg');
    mask-image: url('../assets/img/icons/svg/icon-more.svg');
  }
  &.cil-eye {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-eye.svg');
    mask-image: url('../assets/img/icons/svg/cil-eye.svg');
  }
  &.cil-arrow-circle-left {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-arrow-circle-left.svg');
    mask-image: url('../assets/img/icons/svg/cil-arrow-circle-left.svg');
  }
  &.cil-ellipsis {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-ellipsis.svg');
    mask-image: url('../assets/img/icons/svg/cil-ellipsis.svg');
  }
  &.cil-invoice {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-invoice.svg');
    mask-image: url('../assets/img/icons/svg/cil-invoice.svg');
  }
  &.cil-action-undo {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-action-undo.svg');
    mask-image: url('../assets/img/icons/svg/cil-action-undo.svg');
  }
  &.cil-hand-point-right {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-hand-point-right.svg');
    mask-image: url('../assets/img/icons/svg/cil-hand-point-right.svg');
  }
  &.cil-walk {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-walk.svg');
    mask-image: url('../assets/img/icons/svg/cil-walk.svg');
  }
  &.cil-chevron-left {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-chevron-left.svg');
    mask-image: url('../assets/img/icons/svg/cil-chevron-left.svg');
  }
  &.cil-chevron-right {
    -webkit-mask-image: url('../assets/img/icons/svg/cil-chevron-right.svg');
    mask-image: url('../assets/img/icons/svg/cil-chevron-right.svg');
  }
  &.cil-barcode {
    -webkit-mask-image: url('../assets/img/icons/svg/barcode-solid.svg');
    mask-image: url('../assets/img/icons/svg/barcode-solid.svg');
  }
  &.cil-qr-code {
    -webkit-mask-image: url('../assets/img/icons/svg/qrcode-solid.svg');
    mask-image: url('../assets/img/icons/svg/qrcode-solid.svg');
  }
  &.cil-cloud {
    -webkit-mask-image: url('../assets/img/icons/svg/ci-cloud.svg');
    mask-image: url('../assets/img/icons/svg/ci-cloud.svg');
  }
  &.cil-checkout {
    -webkit-mask-image: url('../assets/img/icons/svg/check-out-icon.svg');
    mask-image: url('../assets/img/icons/svg/check-out-icon.svg');
  }
  &.cil-momo {
    -webkit-mask-image: url('../assets/img/icons/svg/momo-payment.svg');
    mask-image: url('../assets/img/icons/svg/momo-payment.svg');
  }
  &.cil-close {
    -webkit-mask-image: url('../assets/img/icons/close.png');
    mask-image: url('../assets/img/icons/close.png');
  }
  &.cil-cash-register {
    -webkit-mask-image: url('../assets/img/icons/svg/cash-register.svg');
    mask-image: url('../assets/img/icons/svg/cash-register.svg');
  }
  &.cil-excel {
    -webkit-mask-image: url('../assets/img/icons/excel.svg');
    mask-image: url('../assets/img/icons/excel.svg');
  }
}

span {
  &.fa {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    background-color: #ffffff;
    height: 25px;
    -webkit-mask-size: 25px;
    mask-size: 25px;
    width: 25px;
  }

  &.fa-sort {
    -webkit-mask-image: url('../assets/img/icons/svg/sort-solid.svg');
    mask-image: url('../assets/img/icons/svg/sort-solid.svg');
  }

  &.fa-sort-desc {
    -webkit-mask-image: url('../assets/img/icons/svg/arrow-up-desc.svg');
    mask-image: url('../assets/img/icons/svg/arrow-up-desc.svg');
  }

  &.fa-sort-asc {
    -webkit-mask-image: url('../assets/img/icons/svg/arrow-down-short-asc.svg');
    mask-image: url('../assets/img/icons/svg/arrow-down-short-asc.svg');
  }
}

.sort-column {
  position: relative;
  span {
    &.fa {
      height: 15px;
      -webkit-mask-size: 10px;
      mask-size: 10px;
      width: 10px;
      position: absolute;
      right: 5px;
      margin: 0px !important;
      top: 7px;
    }

    &.fa-sort-desc,
    &.fa-sort-asc {
      height: 17px;
      -webkit-mask-size: 18px;
      mask-size: 18px;
      width: 18px;
    }
  }
}

.giftcard-info {
  i {
    background: #3e3a3a;
    width: 20px;
    height: 20px;
  }
  .cil-history {
    background: #fff;
  }
}

.saved-order-list {
  .cil-layers {
    background: #3e3a3a;
  }
}

.pos-footer {
  .btn-footer {
    i {
      width: 24px;
      height: 24px;
      background: #ffffff;
      margin-top: 5px;
    }
    &.btn-custom-light {
      i {
        background: #3e3a3a;
      }
    }
  }
}

.check-in-phone {
  .font-4xl {
    background: #3e3a3a;
    -webkit-mask-size: 0.9rem !important;
    mask-size: 0.9rem !important;
  }
  .font-3xl {
    background: #3e3a3a;
    -webkit-mask-size: 0.725rem !important;
    mask-size: 0.725rem !important;
  }

  .font-5xl {
    background: #3e3a3a;
    -webkit-mask-size: 1.5rem !important;
    mask-size: 1.5rem !important;
    width: 50px;
  }
}

.walk-in-quick-service__right-action {
  .cil-chevron-double-left {
    background: #3e3a3a;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  .cil-check-circle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.form-login {
  i {
    background: #3e3a3a;
    width: 20px;
    height: 20px;
  }
  .cil-paper-plane {
    background: #ffffff;
  }

  .cil-share-alt {
    background: #3e3a3a;
    width: 25px;
    height: 20px;
  }
}
.calendar-navigation,
.modal-body {
  i {
    background: #3e3a3a;
  }
}

.pos-common-modal-custom {
  i {
    background: #ffffff;
  }
}

.navigation-bottom {
  i {
    background: #f86c6b;
  }
}

.react-bs-container-body {
  button {
    display: inline-flex;
    align-items: center;
    i {
      background: #ffffff;
      margin-right: 0.25rem;
    }
  }
}

.search-result-header__header-title {
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5rem;
  }
}
.search-result-header__header-button {
  button {
    display: flex;
    align-items: center;
    i {
      background: #ffffff;
      width: 25px;
      height: 20px;
    }
  }
}

.header-filter-report {
  button {
    display: inline-flex;
    align-items: center;
    i {
      margin-right: 0.25rem;
    }
    .cil-arrow-circle-left {
      background: #3e3a3a;
    }
  }
}

.popover-body {
  button {
    display: inline-flex;
    align-items: center;
    i {
      margin-right: 0.25rem;
    }
  }
}

.modal-footer {
  button {
    i {
      margin-right: 0.25rem;
    }
  }
}

.schedule-detail {
  .modal-footer {
    .col-md-12 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* for walk-in list icon*/
.pos-walk-in-list-data-view {
  button {
    i.cil-low-vision,
    i.cil-pencil,
    i.cil-contact,
    i.cil-clock {
      background: #3e3a3a;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .list-item-detail {
    .display-flex {
      button {
        .cil-pencil {
          width: 16px;
          height: 20px;
        }
        .cil-trash {
          width: 16px;
          height: 20px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .list-item-detail {
    .display-flex {
      button {
        .cil-pencil {
          width: 16px;
          height: 20px;
        }
        .cil-trash {
          width: 16px;
          height: 20px;
        }
      }
    }
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .pos-footer {
    &.pos-employees-footer {
      .align-right {
        float: none;
      }
    }

    &::marker {
      content: '';
    }

    .btn-footer {
      i {
        width: 25px;
        height: 25px;
        margin-right: 0.5rem;
      }
    }
  }
}
