html,
body {
  overscroll-behavior-y: contain;
  overscroll-behavior-x: none;
}
// Here you can add other styles
@font-face {
  font-family: 'Lobster Two';
  src: url('./fonts/LobsterTwo.woff2') format('woff2'), url('./fonts/LobsterTwo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 12px;
  height: 9px; /* height of horizontal scrollbar ← You're missing this */
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}

$label-font-size: 0.95rem !important;

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #a8adaf;
  border-color: #818789;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.inupt-text-capitalize {
  input {
    text-transform: capitalize;
  }
}

.inupt-text-lowercase {
  input {
    text-transform: lowercase;
  }
}

.status-badge {
  display: inline-block;
  padding: 0 6px 1px 6px;
  border-radius: 6px;
}

//New
.status-badge-1,
.status-badge-information {
  background-color: #007acc;
  color: #fff;
}

//Doing
.status-badge-2,
.status-badge-indeterminate {
  background-color: #7709aa;
  color: #fff;
}

// Done
.status-badge-3,
.status-badge-success {
  background-color: #4bae4f;
  color: #fff;
}

//Cancel
.status-badge-4,
.status-badge-warning {
  background-color: #fec006;
  color: #000;
}

//Failed
.status-badge-5,
.status-badge-9,
.status-badge-error {
  background-color: #f34235;
  color: #fff;
}

//Cancel
.status-badge-5,
.status-badge-normal {
  background-color: #2f353a;
  color: #fff;
}

.linePreloader {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, green, green);
  background-color: #ccc;
  margin: auto;
  border-radius: 4px;
  background-size: 20%;
  background-repeat: repeat-y;
  background-position: -25% 0;
  animation: scroll 1.2s ease-in-out infinite;
}

.clear_both {
  clear: both;
}

@keyframes scroll {
  50% {
    background-size: 80%;
  }
  100% {
    background-position: 125% 0;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconAvatar {
  font-size: 6rem !important;
  height: 7rem !important;
  -webkit-mask-size: 6rem !important;
  mask-size: 6rem !important;
  width: 115px;
  background: #777777 !important;
}

.app-header {
  .img-avatar {
    width: 35px;
    height: 35px;
    min-height: 35px;
  }
}

.breadcrumb {
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}

.barcode > img {
  max-width: 100% !important;
}

.barcode {
  text-align: center;
}

.hidden-input {
  display: none !important;
}

button {
  position: relative;
  z-index: 10;
  pointer-events: auto;
}

.btn.disabled {
  cursor: auto;
}

.btn-color {
  display: inline;
  padding: 3px;
  color: #444444;
  margin-left: 10px;
  border: 1px #d8d8d8 solid;
}

.btn-color-option {
  width: 100px;
  height: 35px;
  margin-bottom: 10px;
  border: 1px #d8d8d8 solid;
  cursor: pointer;
  border-radius: 2px;
}

.div-flex {
  display: flex;
}

.div-grid {
  display: grid;
  margin-left: 1%;
}

.btn-primary {
  color: #fff;
  background-color: $main-color;
  border-color: #4f4f4f;
}

.btn-primary:hover,
.btn-primary.selected {
  color: #fff;
  background-color: $main-color-hover;
  border-color: $main-color;
}

.btn-secondary-primary {
  color: #ffffff;
  background-color: $org-color;
  border-color: #b8a099;
}

.btn-secondary-primary:hover,
.btn-secondary-primary.selected {
  color: #ffffff;
  background-color: $org-color-hover;
  border-color: #b8a099;
}

.btn-secondary:hover,
.btn-secondary.selected {
  color: #23282c;
  background-color: #b3bbc2;
  border-color: #a4a9ae;
}

.btn-info {
  color: #fff;
  background-color: $blue-color;
  border-color: #4f4f4f;
}

.btn-info:hover {
  color: #fff;
  background-color: $blue-color-hover;
  border-color: $main-color;
}

.btn-custom-highlight {
  background: $org-color !important;
  color: #ffffff;
  border: none;
}

.btn-custom-highlight:hover {
  background: $org-color-hover !important;
  color: #ffffff;
}

.btn-custom-light {
  background: $dark-white-color !important;
  color: $dark-color;
  border: 1px solid #b0bed0;
  .icon i {
    background: #4f4f4f;
  }
  .text-icon {
    color: #4f4f4f;
  }
  .reload {
    font-size: 1.3rem;
  }

  &:hover {
    i {
      background: #425c70;
    }
  }
}

.btn-custom-light:hover {
  background: #c4dbea !important;
  color: $gray-light-color;
}

.btn-custom-light {
  a {
    .icon i {
      background: #4f4f4f;
    }
    .text-icon {
      color: #4f4f4f;
    }

    &:hover {
      i {
        background: #425c70;
      }
    }
  }
}

.btn-align-middle {
  display: table;
  i {
    display: table-cell;
    vertical-align: middle;
  }
  span {
    display: table-cell;
    vertical-align: middle;
    padding-left: 2px;
  }
}

.btn-change-date {
  padding: 0px;
  margin: 2px;
  background: #67696b;
}

.form-label {
  font-size: $label-font-size;
}

.MuiFormLabel-root {
  font-size: $label-font-size;
}

.MuiOutlinedInput-inputMarginDense {
  padding: 8.5px 7px 10.5px !important;
}

.MuiMenuItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.border-box-layout {
  .MuiOutlinedInput-root {
    background: #ffffff;
  }
}

.text-color-main {
  color: $main-color;
}
.text-color-org {
  color: $org-color;
}

.text-color-gray-light {
  color: $gray-light-color;
}

.text-color-gray {
  color: $gray-color;
}

.text-color-green {
  color: $green-color !important;
}

.text-color-purple {
  color: $purple-color;
}

.text-color-blue {
  color: $blue-color;
}

.text-color-red {
  color: $red-color !important;
}

.text-color-red-light {
  color: $red-light-color !important;
}

.text-color-dark {
  color: $dark-color;
}

.text-weight-500 {
  font-weight: 500;
}

.bg-light {
  background: #f9fafb;
}

.common-texfield-select {
  width: 100%;
}

.common-texfield-input {
  width: 100%;
}

.common-texfield-date {
  width: 100%;
}

.common-texfield-datetime {
  width: 100%;
}

.common-textarea-textfield {
  width: 100%;
}

.text-under-line {
  border-bottom: 2px solid #2f353a;
}

.dash-under-line {
  border-bottom: 1px solid #dbdddf;
  height: 1px;
  margin: 0px 0px 10px 0px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
}

.search-btn-block {
  text-align: right;
  display: flex;
  height: 40px;
  justify-content: left;
}

.mb-show {
  display: none;
}

.desktop-show {
  display: block;
}

.font-7xl {
  font-size: 4.5rem;
}

.pointer {
  cursor: pointer;
}

.btn.disabled {
  cursor: auto;
}

.nav-tooltip {
  cursor: pointer;
}

.app-header {
  .breadcrumb-header.navbar-nav {
    width: 31.6%;
    margin-right: 0px !important;
  }

  .store-header {
    width: 26%;
    .select-store-header {
      width: auto;
      margin: 0 auto;

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      .MuiOutlinedInput-inputMarginDense {
        padding: 10px 5px 0px 5px !important;
        font-family: 'Lobster Two';
        text-align: left;
        font-size: 1.3rem;
        font-weight: bold;
      }

      ::-webkit-full-page-media,
      :future,
      :root .MuiOutlinedInput-inputMarginDense {
        font-family: 'Lobster Two';
      }

      .MuiSelect-select:focus {
        background-color: #ffffff;
      }
    }
  }
  .right-top-header {
    width: 33%;
    margin: 0px;
  }
}

.header-offline-indication {
  margin-right: 4rem;
}

.profile-header {
  .profile {
    display: none;
  }
  .btn-pos-header {
    display: inline-flex;
    padding: 0.5rem 1rem;
    margin: 0rem 4rem 0rem 0.5rem;
    border-radius: 3px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    height: 45px;

    i {
      font-size: 30px;
    }

    span {
      font-size: 1.2rem;
    }
  }

  .btn-clock-in-out {
    width: auto;
    background-color: $main-color;
    color: #fff;
    padding: 0px 1.5rem;
    &:hover {
      background: $main-color-hover;
    }
  }

  .navigation {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px 0px 0px 5px;
    background: var(--white);
    box-shadow: 0 25px 35px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: height 0.15s, width 0.15s;
    transition-delay: 0s, 0.15s;
  }

  .greating {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .navigation .image-box {
    position: relative;
    min-width: 45px;
    height: 45px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ffffff;
    top: -6px;
    right: 4px;
  }

  .navigation .image-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .navigation .menu-toggle {
    position: relative;
    height: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #c8ced3;
    top: 1px;
    right: 0px;
  }

  .menu {
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 60px;
    padding: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .menu li {
    list-style: none;
    display: flex;
    padding: 5px 15px;
    .icon {
      width: 30px;
    }
  }

  .menu li span {
    margin: 5px 0px;
    font-size: 1rem;
    text-decoration: none;
    color: var(--gray);
  }

  .menu li span.label-name {
    margin-top: 2px;
    font-weight: bold;
  }

  .menu li:hover {
    background: #bdbdbd;
  }

  .navigation.active .menu-toggle::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 2px;
    background: #ac0505;
    transform: translateY(0px) rotate(45deg);
    box-shadow: 0 10px var(--gray);
    transition: 0.5s;
    box-shadow: none;
    left: 15px;
  }

  .navigation.active .menu-toggle::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 2px;
    background: #ac0505;
    transition: 0.5s;
    transform: translateY(0px) rotate(-45deg);
    left: 15px;
  }

  .navigation.active {
    width: 300px;
    min-height: 290px;
    transition: width 0.15s, height 0.15s;
    transition-delay: 0s, 0.15s;
  }

  .navigation.active .user-box {
    width: calc(100% - 60px);
    transition-delay: 0s;
    right: 0px;
  }

  .navigation.active .menu-toggle {
    width: 350px;
  }

  .navigation.active .image-box {
    position: absolute;
    top: 6px;
    right: 3px;
  }

  .navigation.active .profile {
    display: block;
  }
}

.user-info {
  .image-box {
    position: relative;
    min-width: 45px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ffffff;

    &.w-45 {
      width: 45px;
      height: 45px;
      margin-right: 5px;
    }
  }
}

.wifi-connect {
  position: relative;
  float: right;
  margin: 8px 10px 0px 10px;

  .cil-check-in {
    width: 45px;
    cursor: pointer;
  }

  .icon-status {
    position: absolute;
    top: 0px;
    left: 45px;
  }
}

.printer-connect {
  position: relative;
  float: right;
  margin: 8px 10px 0px 10px;

  .cil-print {
    width: 35px;
    cursor: pointer;
  }
  .icon-status {
    position: absolute;
    top: 0px;
    left: 32px;
  }
}

.login {
  .head {
    font-size: 1.75rem;
  }

  .quick-login {
    button {
      display: inline-flex;
      align-items: center;
      width: 100%;
      padding: 0.75rem 0.5rem;
      font-size: 1rem;
      text-transform: uppercase;
      i {
        margin-right: 0.25rem;
      }
    }
  }

  .form-login {
    button {
      width: 100%;
      padding: 0.5rem;
      font-size: 1rem;
      text-transform: uppercase;
    }

    .show-password {
      position: absolute;
      right: 6px;
      z-index: 2;
      top: 8px;
      cursor: pointer;
    }
  }

  .form-control {
    font-size: 1rem;
    height: 39px;
    border: 1px solid #c8ced3;
  }

  .login-bg {
    background: radial-gradient(ellipse at center, #f3f5ee, #e3dedc);
    h3 {
      margin: 1.5rem 0px 2rem 0px;
      text-transform: uppercase;
      strong {
        color: #60b1b2;
      }
    }
    .login-logo {
      width: 100%;
      // height: 80%;
      display: table;
      img {
        display: table-cell;
        margin: 15% auto 25% auto;
      }
    }

    .login-demo-account {
      border: 2px solid $red-color;
      padding: 0.5rem 1rem;
      text-align: left;
      border-radius: 3px;
      background-color: #fff;
      p.demo {
        margin-bottom: 5px;
      }
      .demo-account {
        color: $red-color;
      }
    }
  }
}

.employee-avatar {
  .img-avatar {
    width: auto;
  }
}

.permission-block {
  max-height: 424px;
  overflow: auto;
  margin: 0px 0px 10px 0px;
  padding: 0.5rem 1.5rem;
  .form-group {
    margin-bottom: 0.65rem;
  }
}

.same_ticket {
  background-color: #efdcc1;
}

.btn {
  border-radius: 2px;
  i {
    margin-right: 2px;
  }
}

.pos-form-search,
.pos-result-body {
  .btn {
    display: inline-flex;
    justify-items: center;
    align-items: center;
  }
}

.text-strong {
  font-weight: bold;
}

.cover-warp-image {
  width: 120px;
  height: 100px;
  object-fit: contain;
  border: 1px solid #dfdfdf;
  padding: 1px;
  background: #dddddd;
  cursor: pointer;
}

.walk-in-setting {
  .cover-warp-image {
    width: 130px;
  }
  .btn-upload {
    .MuiTypography-body1 {
      font-size: 14px;
    }
  }
}

.main {
  .container-fluid {
    padding: 15px 15px;
  }
}

.pos-form-search {
  margin-top: 0px;
  .card-header {
    display: flex;
    justify-content: space-between;
    .advance-header-filter {
      margin-bottom: 0px;
      width: 100%;
    }
  }
}

.app-body {
  .sidebar {
    flex: 0 0 170px;
    ul.nav {
      width: 50px;
    }
    .nav-item {
      .badge-info {
        display: none;
      }
    }
  }
  .ps-container {
    ul.nav {
      width: auto;
    }
  }
}

.dropdown-menu {
  left: 6px;
  top: 30px;
  border-radius: 0px 0px 3px 3px;
}

.card-header {
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
}

.show-password {
  cursor: pointer;
}
.block-password {
  position: relative;
  .show-password {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}

.extra-option {
  .card {
    border: none;
    .card-header {
      i {
        margin-right: 0px;
        margin-top: 5px;
      }
      .head-title {
        position: relative;
        top: -3px;
      }
      border-bottom: 1px solid #9e9e9e;
    }

    .extra-open {
      border-left: 1px solid #9e9e9e;
      border-right: 1px solid #9e9e9e;
      border-bottom: 1px solid #9e9e9e;
    }
  }
}

.search-result-header {
  .card-header {
    .search-result-header__header-title {
      i {
        font-size: 2rem;
      }
      span {
        position: relative;
        top: -5px;
      }
    }
  }
}

.report-data-list-view {
  .card {
    margin-bottom: 0px;
  }
}

.sidebar-minimized.sidebar-fixed {
  .sidebar {
    width: 60px;
  }
  .sidebar-nav {
    ul.nav {
      width: 60px;
    }
    .nav-item {
      width: 58px;
      border-bottom: 1px solid #ffffff;

      &.open {
        background: #17a2b8;
      }
    }
  }
}

.sidebar-minimized {
  .sidebar {
    .nav-link {
      padding: 0.25rem 0rem 1.05rem 0;
      overflow: hidden;
      position: relative;
      &.active,
      &:hover {
        background: #17a2b8 !important;
      }
      .badge {
        position: absolute;
        bottom: 0.25rem;
        left: 2px;
        font-size: 0.575rem;
        display: block;
        color: #fff;
        z-index: 1;
      }
      .badge-info {
        background: #505e62;
        width: 98%;
        padding: 0.15rem 0;
        z-index: 1;
      }
      .nav-icon {
        color: #fff;
        width: 60px;
        margin-bottom: 0.25rem;
      }
    }
    .nav-item:hover {
      width: 200px;
      background: #ffffff;
      .nav-link {
        margin: 1px;
        background: $main-menu-color;
        width: 99%;
        display: flex;
        align-items: center;
        padding: 0.7rem 0;

        .nav-icon {
          color: #fff;
          width: 37px;
          margin: 0px;
        }
        .badge {
          background-color: #fff;
          right: 5px;
          display: none;
        }
        .badge-info {
          background-color: #fff;
          right: 5px;
        }
      }

      .nav-link .nav-dropdown-toggle {
        margin: 1px 1px 0px 1px;
      }
    }
    .nav-item.nav-dropdown:hover {
      width: 250px !important;
      background: #ffffff;
      .nav-dropdown-items {
        width: 200px;
        background: #ffffff;
        z-index: 2;
        .nav-link {
          margin: 1px 1px 0 1px;
        }
      }
      .nav-item {
        width: 200px;
      }
    }

    .nav-link:hover {
      background: $main-menu-hover-color;
    }

    .ps--active-y {
      .nav-item {
        .nav-dropdown-items .nav-item {
          width: 100% !important;
        }
      }
    }

    .sidebar-minimizer {
      width: 60px;
    }
  }
}

.sidebar {
  .ps-container {
    .nav-link {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      i {
        margin-right: 0.5rem;
        width: 35px;
      }
    }
  }
}

.setting-btn {
  color: #fff;
  background-color: #73818f;
  border-color: #2f353a;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  i {
    height: 2rem;
    -webkit-mask-size: 2rem;
    mask-size: 2rem;
    clear: both;
    display: table;
    width: 100%;
  }
}

html:not([dir='rtl']) .sidebar {
  margin-left: -205px;
}

.warp-gird {
  display: flex;
  .form-group {
    width: 50%;
  }
}

.border-block-right {
  border-right: 2px solid #c8ced3;
  box-shadow: inset;
}

.border-box-layout {
  border: 1px solid #c8ced3;
  padding: 0.75rem;
  border-radius: 2px;
}

.record-calendar:first-child {
  border-bottom: 2px solid #c8ced3;
}

.record-calendar:nth-child(even) {
  background: #dedede;
}
.record-calendar:nth-child(odd) {
  background: #fff;
}

.list-group-item-custom {
  padding: 0.5rem 0.75rem;
  .form-group {
    margin: 0px;
    .MuiFormControlLabel-root {
      margin: 0px;
    }
  }
  .form-check {
    margin: 0px;
    padding-left: 0px;
  }
  .MuiTypography-body1 {
    font-size: $label-font-size;
  }
}

.form-group-no-padding {
  padding: 0;
  .form-group {
    padding: 0px;
    margin: 0;
    .MuiFormControlLabel-root {
      margin-bottom: 0px;
    }
  }
}

.gender-select {
  .form-check {
    padding-left: 0px;
  }
}

.btn-color {
  display: inline;
  padding: 0.25rem 0.75rem;
  color: #444444;
  margin-left: 5px;
  border: 1px #d8d8d8 solid;
}

.search-btn-block {
  text-align: center;
}

.message-block {
  position: relative;
  top: -5px;
  .isNotUse {
    color: green;
  }
  .isUse {
    color: red;
  }
}

.block-point {
  .point-prefix {
    font-size: 1rem;
  }

  .point-format {
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.block-customer-img {
  .imageAvatar {
    max-height: 110px;
    overflow: hidden;
    margin-bottom: 0px;
    img {
      object-fit: cover;
      width: 98%;
    }
  }
}

.image-container {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.warp-avatar {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #dfdfdf;
  border-radius: 30%;
  img {
    width: 100%;
  }
}

.exprired {
  color: #f64846;
  background: #bfc1c3;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 2px;
}

.sale-container {
  .container-fluid {
    padding: 4px 10px 0px 10px;
  }
}

.pos-container {
  .container-fluid {
    padding: 10px 10px;
  }
  .header-filter-report {
    position: relative;
    padding: 15px 15px 0px 15px;
    margin: 0px;
    background-color: #ffffff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

    .calendar-2days {
      display: flex;
      .common-texfield-date {
        width: 48%;
        margin-right: 2%;
      }
    }

    & > div[class*='col-'] {
      padding-left: 0px;
      padding-right: 10px;
    }

    .form-group {
      width: 100%;
      margin-bottom: 0rem;

      .header-select {
        label + .MuiInput-formControl {
          margin-top: 0;
          line-height: 35px;
        }
        .MuiSvgIcon-root {
          font-size: 35px;
        }
        .MuiInputLabel-formControl {
          padding-left: 5%;
          font-size: 18px;
        }

        .MuiSelect-select.MuiSelect-selectMenu {
          padding-left: 5%;
          padding-top: 15px;
        }
      }
    }
    .btn-filter {
      padding: 0.75rem 1rem;
      font-size: 1rem;
    }
  }
}

.report-data-list-view {
  min-height: calc(100vh - 250px);
  overflow-y: hidden;
  max-height: calc(100vh - 200px);
  background-color: $dark-white-color;
  margin: 10px 0px;

  a:hover {
    text-decoration: none;
  }
}

.container_walkin,
.sale-container .container_walkin {
  padding: 0px;
  .screen_walkin {
    background: $dark-white-color;
    height: 100vh;
    padding: 1rem;
    .pos-walk-in-sign-up__right > div {
      button {
        font-size: 1.3rem;
      }
    }
  }
}

.pos-footer {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #fafafa;
  width: 100%;
  padding: 0px;
  display: inline-block;
  margin-top: 0px;
  .align-right {
    float: right;
  }

  .btn-footer {
    align-items: center;
    font-size: 1rem;
    padding: 0rem 1rem;
    margin: 0.5rem;
    height: 4rem;
    position: relative;

    span.icon {
      margin: auto;
      overflow: visible;
      height: auto;
      display: block;
      pointer-events: none;
      transition: opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1), transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    }

    .text-icon,
    .text-img-icon {
      display: block;
      pointer-events: none;
      transition: opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1), transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
      color: #ffffff;
      font-size: 0.825rem;
    }

    .img-icon {
      width: 24px;
    }

    &:hover:not(.btn-custom-light) {
      span.icon i {
        background-color: #c8ced3;
      }
    }

    &.btn-custom-light {
      .text-icon {
        color: #4f4f4f;
      }
      &:hover {
        i {
          background: #425c70;
        }
      }
    }
  }

  .btn-brand {
    align-items: center;
    font-size: 1.2rem;
    padding: 0rem 1rem;
    margin: 0.5rem;
    height: 4rem;
    .text-img {
      margin: 0px;
    }
    .btn-icon {
      width: 34px;
      margin: 0.1rem;
      padding: 0.25rem;
    }
  }
}

.setting-payment {
  .common-checkbox-item {
    padding: 5px;
  }
}

.pos-common-modal-custom {
  padding: 0;
  border-radius: 5px;
  background-color: $dark-white-color;

  .modal-header {
    position: relative;
    padding: 12px 15px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background: #fcfcfc;
    color: #585d63;
    justify-content: flex-end;

    .modal-title {
      text-align: left;
      width: 100%;
      span {
        font-size: 1.2rem;
        color: #585d63;
        padding-left: 0.5rem;
      }
    }

    .btn-close {
      position: absolute;
      right: 0.5rem;
      width: 34px;
      height: 30px;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      font-size: 34px;
      color: $dark-white-color;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
    }
  }

  .modal-body {
    padding: 15px 15px 0px 15px;

    .input-value-area {
      .input-group {
        .unit-amount-type {
          padding: 5px;
          .input-amount-type {
            margin: 0px;
            font-size: 1.6rem;
            font-weight: bold;
          }
        }
      }
    }

    .input-code-area {
      display: inline-block;
      height: 3.5rem;
      line-height: 3.5rem;
      border-radius: 3px;
      box-shadow: inset 0 0 8px 0 rgb(0 0 0 / 5%);
      background-color: #ffffff;
      font-size: 2.5rem;
      text-align: center;
      margin: 0px 0px 1rem 0;
      padding: 0px;
      &::placeholder {
        font-size: 1.5rem;
        color: #bdbdbd;
        line-height: 100%;
      }
    }

    .top-numpad {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 100%;
      .btn-modal-numpad {
        width: 100%;
        font-size: 20px;
        background: #e6e6fa;
        &.keypad-long {
          font-size: 12px;
        }
      }
    }

    .numpad-area {
      display: flex;
      padding-bottom: 1rem;

      .quick-numpad {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr;
        width: 25%;
        padding-right: 10px;

        .btn-modal-numpad {
          background-color: #e6e6fa;
          margin-bottom: 0px;
        }
      }

      .normal-numpad {
        width: 75%;
        &.full-width {
          width: 100%;
        }
        &__main {
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1fr 1fr 1fr;
          width: 100%;
        }
        &__footer {
          padding-top: 0.65rem;
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1fr 2fr;
          padding-right: 5px;
          &___2column {
            padding-top: 0.65rem;
            grid-template-columns: 1fr 1fr;
            display: grid;
            grid-gap: 10px;
            .btn-modal-numpad {
              &--ok {
                width: 100%;
                font-size: 1.75rem;
                height: 65px;
              }
            }
          }
          &.f-width {
            width: 100%;
            grid-template-columns: 1fr;
          }

          .btn-modal-numpad {
            &--ok {
              width: 100%;
              font-size: 1.75rem;
              height: 65px;
              margin: 0px 0px 0px -1px;
            }
          }
        }
      }

      .btn-modal-numpad {
        width: 100%;
        height: 65px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        margin-bottom: 0px;
        font-size: 26px;
        font-weight: 450;
        border-radius: 3px;

        &--return {
          font-size: 30px;
        }
      }

      .btn-modal-numpad:hover {
        background: #f0f3f5;
      }
    }
  }

  .modal-footer {
    padding: 0.5rem 1rem !important;
    border: none;

    .btn {
      width: 100%;
      height: 55px;
      color: $white-color;
      font-size: 26px;
    }
  }
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
  .MuiChip-root {
    height: 30px;
    border-radius: 5px;
  }
}

.schedule-detail {
  .modal-title {
    position: relative;

    .btn-close {
      position: absolute;
      right: 0px;
      width: 34px;
      height: 30px;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      font-size: 1.3rem;
      color: $red-color;
      padding: 0;
      margin: 0;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      background: none;
      i {
        background-color: $red-color;
        height: 1.2rem;
      }
      &:hover {
        background: #c8ced3;
      }
    }
  }
}

.input-group-text {
  cursor: pointer;
  border-radius: 0px 2px 2px 0px;
}

.pos-modal-employee-code {
  width: 364px;
  max-width: 364px;
  .btn-close {
    i {
      top: 0px;
      right: -3px;
      position: absolute;
      width: 30px;
      height: 30px;
      background: #f86c6b !important;
    }
  }

  .input-group-text {
    height: 3.5rem;
  }
}

.modal-dialog.pos-common-modal-custom.base-pos-modal {
  .modal-header {
    .title {
      i {
        top: 2px;
        position: relative;
      }
      .sub-title {
        font-size: 1.2rem;
        position: relative;
        top: -2px;
        left: -5px;
      }
    }
    .btn-close {
      i {
        top: 0px;
        right: -3px;
        position: absolute;
        width: 30px;
        height: 30px;
        background: #f86c6b !important;
      }
    }
  }
  .modal-body {
    .input-value-area {
      margin-bottom: 1rem;
      .input-value {
        border-radius: 2px 0px 0px 2px;
        box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        font-size: 2rem;
        font-weight: bold;
        text-align: right;

        &::placeholder {
          font-size: 26px;
          color: #bdbdbd;
        }
      }
      .input-group-text {
        background: #c8ced3;
        cursor: pointer;
        border-radius: 0px 2px 2px 0px;
        i {
          color: #f86c6b;
          background: #f86c6b !important;
          -webkit-mask-size: 1.2rem !important;
          mask-size: 1.2rem !important;
        }
      }
    }
  }
}

.payroll-modal-dialog.modal-xl {
  max-width: 1250px;
}

.btn-highlight {
  background: #495165;
  color: #ffffff;
}

.btn-pos {
  background: #495165;
  color: #ffffff;
  margin: 0.5rem 1rem;
  padding: 0.4rem 1rem;
  border-radius: 3px;
  .btn-icon {
    width: 35px;
  }
  .text-img {
    font-size: 1.5rem;
    margin: 0.5rem;
  }
  &:hover {
    background: $main-color-hover;
  }
}

.btn-pos-last {
  margin: 0.5rem 0rem 0.5rem 1rem;
}

.modal-body {
  .pos-result-body {
    .responsive-table {
      .responsive-table-header {
        min-width: 100%;
      }
      .responsive-table-body {
        min-width: 100%;
      }
    }
  }
}

.normal-numpad__footer:not(.f-width) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.normal-numpad__footer {
  .numpad-area-container-percent,
  .numpad-area-container-amount {
    width: 50%;
  }
}

.table-details {
  padding: 0px;
  margin: 0px;
  border: 1px solid #c8ced3;
  td {
    padding: 0.5rem;
    &.row-label {
      background: #f0f3f5;
      font-weight: 500;
    }
  }
}

ul.select-block {
  padding: 0px;
  margin: 0px;
  li.li-row {
    padding: 0.25rem;
    list-style: none;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 0.5rem 0px;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    &.active {
      background: #e6f0f4;
      border: 2px solid #006ec8;
      .selected {
        background: #006ec8;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        top: -6px;
        right: -6px;
        i {
          width: 30px;
        }
      }
    }

    .c-col-2 {
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }
  }
}

.radio-horizontal {
  display: flex;
  label.MuiFormLabel-root {
    padding-top: 10px;
    color: #222222;
  }
}

.mb-nav-menu {
  background: #ffffff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: 5px 0px;
  border-top: 2px solid $org-color;
  z-index: 999;
}

.tab-bar {
  box-shadow: 0 4px 12px -1px rgba(18, 22, 33, 0.08);
  border-radius: 6px;
  background: #fff;
  position: relative;

  .tab-bar__tabs {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0px 5px;
    margin: 0px;
    overflow: hidden;
  }
  .tab-bar__tab {
    text-align: center;
    width: 100%;
    padding: 0px;
    margin: 0;
    border-right: 1px solid #dfdfdf;

    button {
      background: none;
      width: 100%;
      padding: 0.25rem;
      margin: 0px;
      border: none;
      &:hover,
      &.active {
        background: #c8ced3;
        border-radius: 5px;
        i {
          background-color: #2196f3;
        }
      }
    }

    button.btn-toggle {
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      background-color: transparent;
      border-color: transparent;
      outline: none;
      transform: translateZ(0);
      transition: transform 0.1s ease-out;
      top: 10px;
      .line {
        display: block;
        width: 40px;
        padding: 2px;
      }
      .line:after {
        content: '';
        display: block;
        width: 100%;
        height: 5.5px;
        background-color: dimgray;
        border-radius: 2px;
        transform: translateZ(0) rotate(0);
        transition: background-color 0.2s ease-out;
      }
      &.open .tab-bar__tab-icon {
        display: inline;
        top: 10px;
      }
      &.open .line:nth-child(1) {
        -webkit-animation: jump-1 0.9s forwards ease;
        animation: jump-1 0.9s forwards ease;
      }
      &.open .line:nth-child(1):after {
        -webkit-animation: line-1 0.9s forwards ease-in-out;
        animation: line-1 0.9s forwards ease-in-out;
      }
      &.open .line:nth-child(2) {
        -webkit-animation: jump-2 0.9s forwards ease;
        animation: jump-2 0.9s forwards ease;
      }
      &.open .line:nth-child(2):after {
        -webkit-animation: line-2 0.9s forwards ease-in-out;
        animation: line-2 0.9s forwards ease-in-out;
      }
      &.close .line:nth-child(1) {
        animation: jump-1 0.9s reverse ease;
      }
      &.close .line:nth-child(1):after {
        animation: line-1 0.9s reverse ease-in-out;
      }
      &.close .line:nth-child(2) {
        animation: jump-2 0.9s reverse ease;
      }
      &.close .line:nth-child(2):after {
        animation: line-2 0.9s reverse ease-in-out;
      }
      &.open .line:nth-child(3),
      &.close .line:nth-child(3) {
        -webkit-animation: jump-3 0.9s forwards ease-out;
        animation: jump-3 0.9s forwards ease-out;
      }
    }
    .btn-toggle:active {
      transform: translateY(4px);
    }
    .btn-toggle:focus .line:after {
      background-color: black;
    }
  }

  .tab-bar__tab-icon,
  .tab-bar__tab-name {
    display: block;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1), transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    color: #425c70;
    white-space: pre;
  }
  .tab-bar__tab-icon {
    margin: auto;
    overflow: visible;
    height: auto;
    position: relative;

    i {
      width: 3rem;
      height: 2rem !important;
      animation-duration: calc(0.3s * 2.5);
      animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      background-color: #425c70;
    }
    span.badge {
      position: absolute;
      top: 2px;
      right: 6px;
    }
  }

  .home .tab-bar__tab-icon {
    transform-origin: 12px 24px;
  }
}

@-webkit-keyframes line-1 {
  10% {
    transform: translateZ(0) rotate(0);
  }
  80% {
    transform: translateZ(0) rotate(395deg);
  }
  90%,
  100% {
    transform: translateZ(0) rotate(405deg);
  }
}

@keyframes line-1 {
  10% {
    transform: translateZ(0) rotate(0);
  }
  80% {
    transform: translateZ(0) rotate(395deg);
  }
  90%,
  100% {
    transform: translateZ(0) rotate(43deg);
  }
}
@-webkit-keyframes line-2 {
  10% {
    transform: translateZ(0) rotate(0);
  }
  20% {
    transform: translateZ(0) rotate(10deg);
  }
  90%,
  100% {
    transform: translateZ(0) rotate(-405deg);
  }
}
@keyframes line-2 {
  10% {
    transform: translateZ(0) rotate(0);
  }
  20% {
    transform: translateZ(0) rotate(10deg);
  }
  90%,
  100% {
    transform: translateZ(0) rotate(-405deg);
  }
}
@-webkit-keyframes jump-1 {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px);
  }
  90%,
  100% {
    transform: translateY(-7.5px);
  }
}
@keyframes jump-1 {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px);
  }
  90%,
  100% {
    transform: translateY(-7.5px);
  }
}
@-webkit-keyframes jump-2 {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-75px);
  }
  85%,
  100% {
    transform: translateY(-22.5px);
  }
}
@keyframes jump-2 {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-75px);
  }
  85%,
  100% {
    transform: translateY(-17px);
  }
}
@-webkit-keyframes jump-3 {
  10% {
    transform: translateY(-7.5px) rotate(15deg);
  }
  30% {
    transform: translateY(-30px) rotate(-10deg);
  }
  50% {
    transform: translateY(7.5px) rotate(5deg);
  }
  80% {
    transform: translateY(0);
  }
}
@keyframes jump-3 {
  10% {
    transform: translateY(-7.5px) rotate(15deg);
  }
  30% {
    transform: translateY(-30px) rotate(-10deg);
  }
  50% {
    transform: translateY(7.5px) rotate(5deg);
  }
  80% {
    transform: translateY(0);
  }
}
@-webkit-keyframes glow {
  50% {
    box-shadow: rgba(131, 131, 131, 0.4) 0 0 2px 2px;
  }
}
@keyframes glow {
  50% {
    box-shadow: rgba(131, 131, 131, 0.4) 0 0 2px 2px;
  }
}

@media (min-width: 1366px) and (max-width: 1823px) {
  .pos-container {
    .header-filter-report {
      .col-xl-1 {
        flex: 0 0 12.3333333333%;
        max-width: 12.3333333333%;
      }
      .col-xl-2 {
        flex: 0 0 15.3333333333%;
        max-width: 15.3333333333%;
      }
    }
  }

  .sale-container {
    .main {
      .container-fluid {
        padding: 0px 10px;
      }
    }
    .payment-sale {
      .payment-left {
        width: 70px !important;
        button {
          padding: 0.25rem 0.25rem 0.2rem 0.25rem;
          .font-sm {
            font-size: 0.7rem !important;
          }
        }
      }

      .payment-right {
        height: 52.5vh;
        width: calc(100% - 70px);
        .btn-receipt-group {
          padding-top: 3px;
          padding-left: 5px;
        }
      }

      .order-footer-left {
        .btn-group {
          margin-bottom: 0.35rem;
          button {
            padding: 0.5rem 0.25rem;
            margin-bottom: 0px;
          }
          .btn-sell-giftcard {
            .text-icon {
              font-size: 0.725rem;
            }
          }
          .img-icon {
            width: 30px;
          }
          .btn-order-dark {
            .font-4xl {
              height: 1.5rem !important;
              -webkit-mask-size: 1.5rem !important;
              mask-size: 1.5rem !important;
            }
            span {
              font-size: 0.8rem;
              margin-bottom: 0px;
            }
            .mb-1 {
              margin-bottom: 0px;
            }
          }
        }
        .btn-group-inline {
          #btn-point {
            padding: 0.5rem 0.25rem 0rem;
          }
        }
      }

      .order-footer-right {
        .table tr td {
          font-size: 0.75rem;
          padding: 3.5px;
        }
      }
      .payment-footer {
        height: 60px;
        max-height: 60px;
        padding-top: 0px;
        margin-top: 10px;
        .is_utiliy_tool {
          .menu-item {
            width: 49% !important;
          }
        }
        .nav-warp {
          .menu-item {
            padding: 0.25rem 0.5rem 0.5rem 0.5rem;
            a {
              height: 50px;
              min-height: 50px;
            }
          }
        }
      }
    }

    .payment-type {
      .nav-warp {
        li {
          a {
            min-height: 50px;
            height: 58px;
            span.text-uppercase {
              font-size: 0.625rem;
            }
            .gift-card {
              font-size: 0.7rem;
              margin-top: 0.2rem;
            }
            .fee-cover {
              font-size: 0.45rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .pos-container {
    .header-filter-report {
      .btn-filter {
        font-size: 0.8rem;
      }
    }
  }

  .pos-form-search .btn,
  .pos-result-body .btn {
    font-size: 0.75rem;
  }

  .react-bs-container-body button i {
    margin-right: 0rem;
  }

  .label-payment-size {
    font-size: 0.8rem;
    float: left;
    margin: 0px;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1024px) and (max-width: 1365px) {
  .app-header {
    .right-top-header {
      width: 32%;
    }
  }

  .sale-container {
    .page-sale-main-body {
      .sale-right-container .sale-right-paging nav {
        margin-top: 5px;
      }
      .sale-right-container .sale-item .sale-item-detail button {
        width: 33%;
      }
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .app-header .navbar-brand {
    display: none;
  }
  //menu mobile bottom
  .mb-show {
    display: block;
  }

  .mb-none {
    display: none;
  }
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .sidebar-show.sidebar-fixed.sidebar-minimized {
    .main {
      margin-left: 175px !important;
    }
  }
  .sidebar {
    width: 170px;
    .nav-item.nav-dropdown:hover {
      width: 200px;
    }
    .sidebar-nav {
      width: 170px;
    }
  }

  .sidebar-minimized.sidebar-fixed {
    .sidebar,
    .sidebar-nav {
      width: 170px;
      ul.nav {
        width: 170px;
      }
      .nav-item {
        width: 170px;
        border-bottom: 1px solid #ffffff;
        .badge-info {
          display: none;
        }
        .nav-link {
          padding: 0.5rem;
          .nav-icon {
            width: 30px;
          }
        }
      }
    }
  }

  .store-header {
    display: none;
  }

  .dashboard {
    .col-sm-6 {
      margin-bottom: 1rem;
    }
  }

  .footer-report {
    .btn-footer {
      padding: 0.25rem 0.5rem !important;
      font-size: 1.2rem !important;
      i {
        font-size: 1rem !important;
      }
      .text-icon {
        top: -1px !important;
      }
    }

    .btn-brand {
      padding: 0.25rem 0.5rem !important;
      font-size: 1.2rem !important;
      .btn-icon {
        width: 24px !important;
      }
    }

    .btn-pos {
      margin-top: -46px !important;
    }
  }

  .pos-container {
    .pos-data-view {
      height: calc(100vh - 328px);
      max-height: calc(100vh - 328px);
    }
  }

  .pos-footer {
    display: inline-block;
    padding: 0.5rem;
    .btn-footer {
      padding: 0rem 0.5rem;
      width: auto;
      float: left;
      margin: 0px 6px 0px 0px !important;
      i {
        font-size: 1.75rem;
        width: 25px;
        height: 20px;
      }
      .text-icon {
        font-size: 0.825rem;
        top: -3px;
      }
    }

    .btn-brand {
      padding: 0.4rem 1rem;
      margin: 0.7rem 0.5rem 0.5rem 0.5rem;
      float: left;
      .text-img {
        margin: 0px;
      }
      .btn-icon {
        width: 34px;
        margin: 0.1rem;
        padding: 0.25rem;
      }
    }
  }

  .container_walkin {
    .screen_walkin {
      margin-top: 0px;

      .pos-walk-in-sign-up__right-title {
        font-size: 2rem;
      }

      .numpad-area-container {
        padding: 0px;
        .check-in-phone {
          padding: 0px;
          max-width: 420px;
        }

        .pos-walk-in-new-form__right {
          font-size: 1rem;
          .h3 {
            font-size: 1.2rem;
          }
        }
      }
    }

    .walk-in-quick-service__right {
      padding: 0;
      width: 100%;
    }
  }

  .modal-dialog {
    max-width: 98%;
    .modal-lg {
      .modal-content {
        width: 100%;
      }
    }
  }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1198px) and (orientation: landscape) {
  .sale-container {
    .app-header {
      display: none;
    }
    .app-body {
      margin-top: 5px;
    }
  }

  .sidebar-minimized.sidebar-fixed {
    .sidebar-nav {
      ul.nav {
        width: auto;
      }
    }
  }
  .footer-report {
    .btn-footer {
      padding: 0.25rem 0.5rem !important;
      font-size: 1.2rem !important;
      margin: 0.5rem !important;
      i {
        font-size: 1.3rem !important;
      }
      .text-icon {
        top: -1px !important;
      }
    }

    .btn-brand {
      padding: 0.25rem 0.5rem !important;
      font-size: 1.2rem !important;
      .btn-icon {
        width: 24px !important;
      }
    }
  }

  .page-sale-main {
    .page-left-warp {
      width: 55%;
      .employee-box {
        .employee-ticket {
          h4 {
            font-size: 1rem;
          }
        }
      }
    }

    .payment-sale {
      width: 45%;
    }
  }

  .page-sale-main-body {
    height: calc(100vh - 175px) !important;
  }

  .sale-container {
    .main {
      .container-fluid {
        padding: 0px 10px;
      }
    }
    .payment-sale {
      .payment-left {
        width: 70px !important;
        button {
          padding: 0.35rem 0.25rem 0.3rem 0.25rem;
          .font-sm {
            font-size: 0.7rem !important;
          }
        }
      }

      .payment-right {
        height: 52.5vh;
        width: calc(100% - 70px);
        .list-item {
          height: 93%;
        }
        .btn-receipt-group {
          padding-top: 3px;
          padding-left: 5px;
        }
      }

      .order-footer-left {
        .btn-group {
          margin-bottom: 0.35rem;
          button {
            padding: 0.5rem 0.25rem;
            margin-bottom: 0px;
          }
          .btn-sell-giftcard {
            .text-icon {
              font-size: 0.725rem;
            }
          }
          .img-icon {
            width: 30px;
          }
          .btn-order-dark {
            .font-4xl {
              height: 1.5rem !important;
              -webkit-mask-size: 1.5rem !important;
              mask-size: 1.5rem !important;
            }
            span {
              font-size: 0.8rem;
              margin-bottom: 0px;
            }
            .mb-1 {
              margin-bottom: 0px;
            }
          }
        }
        .btn-group-inline {
          #btn-point {
            padding: 0.5rem 0.25rem;
          }
        }
      }

      .order-footer-right {
        .table tr td {
          font-size: 0.75rem;
          padding: 3.5px;
        }
      }
      .payment-footer {
        height: 70px;
        max-height: 70px;
        padding-top: 0px;
        margin-top: 0px;
        .is_utiliy_tool {
          .menu-item {
            width: 49% !important;
          }
        }
        .nav-warp {
          .menu-item {
            padding: 0.25rem 0.5rem 0.5rem 0.5rem;
            a {
              height: 50px;
              min-height: 50px;
            }
          }
        }
      }
    }

    .payment-type {
      .nav-warp {
        li {
          a {
            min-height: 50px;
            height: 58px;
            span.text-uppercase {
              font-size: 0.625rem;
            }
            .gift-card {
              font-size: 0.7rem;
              margin-top: 0.2rem;
            }
            .fee-cover {
              font-size: 0.45rem;
            }
          }
        }
      }
    }
  }

  .permission-block {
    margin: 0px;
    padding: 0.5rem 1.5rem;
    .col-md-6 {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  .pos-footer {
    display: flex;
    .btn-footer {
      padding: 0rem 1rem;
      width: auto;
      i {
        font-size: 1.75rem;
        width: 25px;
        height: 20px;
      }
      .text-icon,
      .text-img-icon {
        font-size: 0.9rem;
        top: 0;
        line-height: 120%;
      }
      .img-icon {
        width: 25px;
      }
    }
  }

  .screen_walkin {
    .pos-walk-in-new-form__left,
    .pos-walk-in-sign-up__left {
      .form-bg-logo {
        img {
          width: 150px;
        }
      }
    }
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .MuiMenuItem-root {
    min-height: 25px !important;
  }

  .scrollbar-container {
    overflow: hidden;
  }

  .pos-modal-employee-code {
    margin: 50px auto;
  }

  .sidebar-minimized {
    .sidebar {
      .nav-item:hover {
        width: 200px;
      }
      .nav-link {
        padding: 0.5rem 1rem 0.5rem 0rem;
        .nav-icon {
          width: 40px;
        }
        .badge-info {
          display: none;
        }
        .font-3xl {
          height: 1.25rem !important;
          -webkit-mask-size: 1.25rem !important;
          mask-size: 1.25rem !important;
        }
      }
    }
  }

  .sidebar-minimized.sidebar-fixed {
    .sidebar {
      width: 205px;
      .sidebar-nav {
        width: 100%;
        ul.nav {
          width: 100%;
        }
        .nav-item {
          width: 100%;
        }
      }
    }
  }

  .main .container-fluid {
    padding: 5px;
  }

  .pos-container {
    .app-body .main {
      padding: 15px 25px;
    }

    .header-filter-report {
      padding-bottom: 15px;
      .btn-filter {
        padding: 0.5rem 1rem;
      }
      .pr-4 {
        padding-right: 0px !important;
      }
    }

    .search-result-header__header-title {
      min-width: 150px;
    }
  }

  .app-header {
    .navbar-brand {
      display: none;
    }
    .breadcrumb {
      .breadcrumb-item {
        font-size: 0.9rem;
      }
    }
    .profile-header {
      .greating {
        display: none;
      }
    }
  }

  .settings {
    .col-sm-4 {
      width: 48%;
      padding: 0px;
      margin-right: 1% !important;
    }
  }
  .react-bs-table-pagination {
    > .row {
      .col-md-6 {
        width: 50%;
        padding: 0px;
        span:first-child {
          display: none;
        }

        button#pageDropDown {
          margin-left: 0px;
        }
      }
    }
  }
  .pos-form-search {
    .p-2 {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
    }
    .search-btn-block {
      justify-content: center;
    }
    .mobile-mb-2 {
      margin-bottom: 1rem;
    }
  }

  .container_walkin {
    .screen_walkin {
      padding: 0.5rem !important;
    }

    .walk-in-quick-service__right {
      padding: 0;
      width: 100%;

      .walk-in-quick-service__right-action {
        right: -1.5rem;
        bottom: 0px;
      }
    }

    .pos-walk-in-sign-up button {
      font-size: 1.3rem;
      margin: 5px;
      &.btn-check-in {
        background: #5e6162;
      }
    }
  }

  .mb-show {
    display: block;
  }

  .mb-none {
    display: none;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .mb-show {
    display: block;
  }

  .desktop-show {
    display: none !important;
  }

  .pos-modal-employee-code {
    margin: 50px auto;
  }

  .app-body {
    margin-top: 45px;
  }
  .app-header .navbar-brand {
    display: none;
  }
  .store-header {
    display: none;
  }

  .mb-show {
    display: block;
  }

  .mb-none {
    display: none;
  }

  .app-header .breadcrumb-header.navbar-nav {
    width: 85%;
  }

  .MuiMenuItem-root {
    min-height: 25px !important;
  }

  .scrollbar-container {
    overflow: hidden;
  }

  .sidebar-minimized {
    .sidebar {
      .nav-item:hover {
        width: 200px;
      }
      .nav-link {
        padding: 0.5rem 1rem 0.5rem 0rem;
        .nav-icon {
          width: 40px;
        }
        .badge-info {
          display: none;
        }
        .font-3xl {
          height: 1.25rem !important;
          -webkit-mask-size: 1.25rem !important;
          mask-size: 1.25rem !important;
        }
      }
    }
  }

  .sidebar-minimized.sidebar-fixed {
    .sidebar {
      width: 205px;
      .sidebar-nav {
        width: 100%;
        ul.nav {
          width: 100%;
        }
        .nav-item {
          width: 100%;
        }
      }
    }
  }

  .main .container-fluid {
    padding: 5px;
  }

  .pos-container {
    .app-body .main {
      padding: 2px;
    }

    .header-filter-report {
      padding-bottom: 15px;
      .calendar-2days {
        margin-bottom: 15px;
      }
      .btn-filter {
        padding: 0.5rem 1rem;
        &.btn-secondary {
          order: 1;
          margin-right: 5px;
        }
        &.btn-primary {
          order: 2;
        }
      }

      .pr-4 {
        padding-right: 0px !important;
      }
    }

    .search-result-header__header-title {
      min-width: 180px;
    }

    .report-data-list-view {
      .card {
        margin-bottom: 3rem;
      }
    }
  }

  .filter-action-block {
    &.d-flex {
      align-items: center;
      justify-content: center;
    }
    &.mb-3 {
      margin-bottom: 0px !important;
    }
  }

  .app-header {
    height: 45px;
    .navbar-brand {
      display: none;
    }
    .breadcrumb {
      .breadcrumb-item {
        font-size: 0.9rem;
      }
    }
    .right-top-header {
      position: absolute;
      top: 0px;
      right: 0px;
      .header-offline-indication {
        .printer-connect {
          margin: 10px 5px 0px;
          i.font-6xl {
            height: 1.75rem !important;
            -webkit-mask-size: 1.75rem !important;
            mask-size: 1.75rem !important;
            width: 35px;
          }
          .icon-status {
            left: 30px;
            top: -5px;
          }
        }
      }
    }
    .profile-header {
      .greating {
        display: none;
      }
      .btn-pos-header {
        span {
          font-size: 0.9rem;
        }
      }
      .btn-clock-in-out {
        padding: 0px 1.5rem 0px 1rem;
      }

      .menu {
        margin-top: 47px;
      }

      .navigation {
        height: 45px;
        .menu-toggle {
          height: 45px;
        }
        .image-box {
          min-width: 40px;
          height: 40px;
          top: -1px;
        }
        &.active {
          .image-box {
            top: 2px;
          }
        }
      }
    }
  }

  .settings {
    .col-sm-4 {
      width: 48%;
      padding: 0px;
      margin-right: 1% !important;
    }
  }
  .react-bs-table-pagination {
    > .row {
      .col-md-6 {
        width: 50%;
        padding: 0px;
        span:first-child {
          display: none;
        }

        button#pageDropDown {
          margin-left: 0px;
        }
      }
    }
  }
  .pos-form-search {
    .p-2 {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
    }
    .mobile-mb-2 {
      margin-bottom: 1rem;
    }
  }

  .search-btn-block {
    justify-content: center;
    .btn-primary {
      order: 2;
    }
    .btn-secondary {
      order: 1;
    }
  }

  .container_walkin {
    .screen_walkin {
      padding: 0.5rem !important;
    }

    .col-6.pos-walk-in-new-form__left,
    .pos-walk-in-sign-up__left {
      margin: 0px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    .col-6.pos-walk-in-new-form__right,
    .col-6.pos-walk-in-sign-up__right {
      margin: 0px;
      max-width: 100%;
      flex: 0 0 100%;
      padding: 15px;
      display: inline-block;

      .numpad-area-container {
        .check-in-phone {
          .form-group {
            width: 100%;
          }
        }
      }

      .pos-walk-in-new-form__right-title {
        font-size: 2rem;
        margin: 1.5rem 0;
      }

      .pos-walk-in-sign-up__right-title {
        font-size: 2rem;
        margin: 1.5rem;
      }

      .h3 {
        font-size: 1.2rem;
      }

      .numpad-area-container .numpad-area .btn-modal-numpad {
        flex: 1 1 100px;
        height: 75px;
        overflow: hidden;
        &.btn-clear-item {
          font-size: 1.7rem;
        }
      }

      .qrCode-mb {
        margin-top: 1rem;
      }
    }

    .walk-in-quick-service__right {
      padding: 0;
      width: 100%;
      .walk-in-quick-service__right-title {
        font-size: 0.825rem;
      }

      .walk-in-quick-service__right-action {
        position: fixed;
        right: 0px;
        bottom: 0px;
        left: 0px;

        button.btn-back {
          margin-left: 0px;
        }
        button.btn-next {
          margin-right: 0px;
        }
      }
    }

    .pos-walk-in-sign-up button {
      font-size: 1.3rem;
      margin: 5px;
      &.btn-check-in {
        background: #5e6162;
      }
    }
  }

  .sale-add-employee {
    cursor: pointer;
    .text-name {
      font-size: 0.9rem;
    }
  }

  .pos-common-modal-custom {
    &.cash-payment-pos-modal,
    &.creditcard-payment-pos-modal,
    &.venmo-payment-pos-modal,
    &.point-payment-pos-modal,
    &.check-payment-pos-modal,
    &.giftcard-payment-pos-modal {
      width: 96%;
      .modal-body {
        .numpad-area .quick-numpad {
          .btn-modal-numpad {
            font-size: 15px;
            font-weight: bold;
            height: 65px;
          }
        }
      }
    }
  }

  .react-bs-table-tool-bar {
    .text-right {
      &.col {
        padding: 0px 10px 0px 0px;
      }
    }
    .btn.btn-secondary.btn-md {
      padding: 5px 0px;
    }
  }

  .tab-bar {
    .tab-bar__tab {
      button {
        .tab-bar__tab-name {
          font-size: 0.825rem;
        }
      }
    }
  }
}

@media (pointer: none), (pointer: coarse) {
  .common-texfield-date,
  .common-texfield-time {
    position: relative !important;
  }
  .common-texfield-date::after {
    position: absolute !important;
    content: ' ';
    width: 16px;
    height: 16px;
    right: 5px;
    top: 10px;
    background: url('../assets/img/calendar-icon.png') no-repeat;
    background-size: contain;
  }
  .common-texfield-time::after {
    position: absolute !important;
    content: ' ';
    width: 16px;
    height: 16px;
    right: 5px;
    top: 10px;
    background: url('../assets/img/clock.png') no-repeat;
    background-size: contain;
  }

  .first-date .common-texfield-date::after {
    top: 6px;
    right: 5px;
  }

  .time-from .common-texfield-time::after,
  .time-end .common-texfield-time::after {
    top: 6px;
    right: 5px;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  html:not([dir='rtl']) {
    .sidebar-minimized {
      .sidebar {
        margin-left: -60px;
      }
    }

    .sidebar-show,
    .sidebar-lg-show {
      .sidebar {
        margin-left: 0px;
      }
      .main,
      .app-footer {
        margin-left: 60px;
      }
    }
  }

  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 60px;
  }
}

.btn-icon-w40 {
  width: 30px;
  height: 30px;
}

.help-widget {
  border: none;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .store-header {
      .select-store-header {
        .MuiOutlinedInput-inputMarginDense {
          font-family: Phosphate;
        }
      }
    }
  }
}
